"use client";

import React, { FC, useEffect, useState, useCallback } from "react";
import { RenderLayout } from "@/app/(root)/RenderLayout";
import { setLandingPage } from "@/siteConfig/settings/layoutSetting";
import { setMobileLandingPage } from "@/siteConfig/settings/mobileLayoutSetting";
import { RouteContext } from "@/contexts/routeContext";

const Home: FC = () => {
    const { requestedAt } = React.useContext(RouteContext);
    const [isClient, setIsClient] = useState(false);
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    const layout = setLandingPage()
    const mobileLayout = setMobileLandingPage()

    const resize = useCallback(() => {
        if (typeof window !== 'undefined') {
            const shouldBeMobile = window.innerWidth < 768;
            if (shouldBeMobile !== isMobile) setIsMobile(shouldBeMobile);
        }
    }, [isMobile]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', resize);
            resize();

            return () => {
                window.removeEventListener('resize', resize);
            };
        }
    }, [resize]);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) return null;

    return (
        <RenderLayout layout={isMobile ? mobileLayout : layout} key={requestedAt?.toString() ?? ""} />
    );
};

export default Home;
